<template>
  <!-- 添加编辑代理页面 -->
  <div class="positionT0L0">
    <p class="title"><span></span>基本信息</p>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 9 }"
    >
      <a-form-model-item
        label="选择用户"
        :class="{ 'has-error': !isShowUser && isUser }"
        required
      >
        <div class="product-box" v-if="isShowUser">
          <a-icon
            v-show="$route.params.id == '0'"
            type="close-circle"
            :style="{ fontSize: '20px', color: '#9FA5AF' }"
            class="product-close"
            @click="closeHandle"
          />
          <div class="product-wrap flexbox justify middle">
            <div class="left-wrap flex1 flexbox left">
              <div class="product-img">
                <img class="p-img" :src="userDetail.headUrl" alt="图片" />
              </div>
              <div class="product-info flex1">
                <div style="height: 56px">
                  <div class="p-title more-t">
                    {{ userDetail.realName }} ({{ userDetail.userName }})
                  </div>
                  <div class="p-price">{{ userDetail.phone }}</div>
                </div>
              </div>
            </div>
            <div class="right-wrap" v-show="$route.params.id == '0'">
              <a-button type="primary" @click="editUser">更换用户</a-button>
            </div>
          </div>
        </div>
        <a-button v-else type="primary" @click="selectUserClick">
          选择用户
        </a-button>
        <div class="ant-form-explain" v-if="!isShowUser && isUser">
          请选择用户
        </div>
      </a-form-model-item>
      <a-form-model-item label="代理等级" prop="agentLevel">
        <a-select
          show-search
          v-model="form.agentLevel"
          placeholder="点击选择代理等级"
          option-filter-prop="children"
          style="width: 120px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="agentLevelChange"
        >
          <a-select-option
            :value="item.level"
            v-for="item in agentLevelArr"
            :key="item.level"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        v-if="form.agentLevel > 1"
        label="上级代理"
        :class="{ 'has-error': isShowParentAgentError }"
        required
      >
        <a-tag v-if="form.parentAgentName" closable @close="deleteParentAgent">
          {{form.parentAgentName}}
        </a-tag>
        <a-button type="primary" @click="selectParentAgent">
          选择上级代理
        </a-button>
        <div class="ant-form-explain" v-if="isShowParentAgentError">
          请选择上级代理
        </div>
      </a-form-model-item>

      <a-form-model-item label="选择区域" prop="provinceCode">
        <a-select
          show-search
          v-model="form.provinceCode"
          placeholder="点击选择省"
          option-filter-prop="children"
          style="width: 120px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="provinceCodeChange"
        >
          <a-select-option
            :value="item.code"
            v-for="item in provinceCodeArr"
            :key="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-show="this.form.provinceCode"
          show-search
          v-model="form.cityCode"
          placeholder="点击选择市"
          option-filter-prop="children"
          style="width: 120px; margin-left: 20px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="cityCodeChange"
        >
          <a-select-option
            :value="item.code"
            v-for="item in cityCodeArr"
            :key="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>

        <a-select
          v-show="this.form.cityCode && this.form.agentLevel > 1"
          show-search
          v-model="form.countryCode"
          placeholder="点击选择县"
          option-filter-prop="children"
          style="width: 120px; margin-left: 20px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="countryCodeChange"
        >
          <a-select-option
            :value="item.code"
            v-for="item in countryCodeArr"
            :key="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="保证金">
        <a-input-number
          v-model="form.securityDeposit"
          maxLength="5"
          :min="0"
          :max="9999999.99"
          :precision="2"
          placeholder="请输入保证金"
          style="width: 120px"
        ></a-input-number>
      </a-form-model-item>

      <a-form-model-item label="预购会员卡数量">
        <a-input-number
          v-model="form.membershipNumber"
          :min="0"
          placeholder="请输入预购会员卡数量"
          style="width: 120px"
        ></a-input-number>
      </a-form-model-item>

      <a-form-model-item
        label="分成比例"
        prop="ratio"
        required
      >
        <a-input-number
          v-model="form.ratio"
          :min="0"
          :max="100"
          :formatter="value => `${value}%`"
          :parser="value => value.replace('%', '')"
          placeholder="请输入分成比例"
          style="width: 120px"
        ></a-input-number>
      </a-form-model-item>

      <a-form-model-item
        label="有效期"
        prop="validPeriodStart"
      >
        <a-range-picker
          :value="rangePicker"
          @change="onDateRangeChange" />
      </a-form-model-item>
      <a-form-model-item
        label="代理商品"
        prop="agentGoodsType"
        :class="{
          'has-error':
            tableList.length == 0 && isGoods
        }"
        required
      >
        <a-button
            type="primary"
            @click="addGoods"
          >
            添加商品
        </a-button>
        <!-- <a-radio-group
          name="radioGroup"
          :value="form.agentGoodsType"
          @change="agentGoodsTypeChange"
        >
          <a-radio :value="1"> 全部商品 </a-radio>
          <a-button
            v-show="form.agentGoodsType == 1"
            type="primary"
            @click="addGoods"
          >
            设置成本
          </a-button>
          <p>
            <a-radio :value="4"> 指定商品 </a-radio>
            <a-button
              v-show="form.agentGoodsType == 4"
              type="primary"
              @click="addGoods"
            >
              添加商品
            </a-button>
          </p>
        </a-radio-group> -->
        <div v-if="tableList.length > 0 && form.agentGoodsType == 4">
          <div class="p-container">
            <a-list item-layout="horizontal" :data-source="tableList">
              <a-list-item slot="renderItem" slot-scope="item">
                <div class="info-wrap flexbox middle justify">
                  <div class="item-info flexbox flex1">
                    <img
                      class="p-img"
                      :src="item.coverUrl ? item.coverUrl : item.coverImg"
                      alt=""
                    />
                    <div class="p-info flex1">
                      <div class="p-wrap">
                        <div class="p-info-name more-t">
                          {{ item.name ? item.name : item.memberName }}
                        </div>
                        <!-- <div class="p-info-price">￥{{ item.price }}</div> -->
                        <div
                          v-show="item.courseType == 1 && item.pricingType == 0"
                          class="p-info-price"
                        >
                          免费
                        </div>
                        <div
                          v-show="item.courseType == 1 && item.pricingType == 1"
                          class="p-info-price"
                        >
                          ￥{{ item.price.toFixed(2) }}
                        </div>
                        <div
                          v-show="item.courseType == 1 && item.pricingType == 2"
                          class="p-info-price"
                        >
                          不支持单独售卖
                        </div>
                        <div v-show="item.courseType != 1" class="p-info-price">
                          ￥{{ item.price.toFixed(2) }}
                        </div>
                      </div>
                      <div class="p-info-type">
                        商品类型 ：{{ item.courseType == 1 ? "课程" : "会员" }}
                        成本：￥{{item.cost}}
                      </div>
                    </div>
                  </div>
                  <div>
                    <a @click="delHandle(item)"
                      ><a-icon type="close" style="color: #b2b2b2"
                    /></a>
                  </div>
                </div>
              </a-list-item>
            </a-list>
          </div>
        </div>
        <div
          class="ant-form-explain"
          v-if="tableList.length == 0 && isGoods"
        >
          请选择商品
        </div>
      </a-form-model-item>
    </a-form-model>

    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="backClick">返回</a-button>
      <a-button type="primary" @click="keepClick" :disabled="isDisabled"
        >保存</a-button
      >
    </FooterToolBar>
    <RadioModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :isParentAgent="isParentAgent"
      @ok="modalOk"
      @cancel="modalCancel"
    />
    <!-- 模态框 -->
    <CheckboxModal
      v-if="checkboxModalVisible"
      :modalVisible="checkboxModalVisible"
      :fuCourseSelectedRowKeys="fuCourseSelectedRowKeys"
      :fuMemberlectedRowKeys="fuMemberlectedRowKeys"
      :fuCourseRows="fuCourseRows"
      :fuMemberRows="fuMemberRows"
      :isShowMember="true"
      @cancel="checkboxCancel"
      @ok="checkboxOk"
    />
  </div>
</template>

<script>
import moment from "moment";
import FooterToolBar from "@/components/FooterToolbar";
import RadioModal from "./modal/RadioModal";
import CheckboxModal from "./modal/CheckboxModal";
import { baseMixin } from "@/store/app-mixin";
import { message } from "ant-design-vue";
import {
  GgentSelectlabelApi,
  GgentSettingApi,
  AgentUpdateApi,
  GgentUserInfoApi,
} from "@/request/api/marketingCenter";
import {
  ShowAllProvinceApi,
  GetAllCityByCodeApi,
  GetAllCountryByCityApi
} from "@/request/api/orderformManage";
import {AgentLevel} from '@/utils/constants';

export default {
  mixins: [baseMixin],
  components: {
    FooterToolBar,
    RadioModal,
    CheckboxModal
  },
  created() {
    // this.GgentSelectlabelFn();
    this.ShowAllProvinceFn();
    if (this.$route.params.id != "0") {
      this.isShowUser = true;
      this.GgentUserInfoFn();
    }
    this.agentLevelArr = Object.keys(AgentLevel).map(level => {
      return {
        level: +level,
        text: AgentLevel[level]
      }
    });

    const id = Number(this.$route.params.id);
    this.isEdit = !!id;
  },
  data() {
    return {
      isEdit: false, // 是否为编辑代理
      checkboxModalVisible: false, // 模态框的显示隐藏
      fuCourseSelectedRowKeys: [], // 课程选中courseNo数组
      fuMemberlectedRowKeys: [], // 会员已选择的memberNo数组
      fuCourseRows: [], // 选中课程对象数组
      fuMemberRows: [], // 选中课程对象数组
      modalVisible: false, // 模态框的显示隐藏
      isDisabled: false, // 保存的按钮是否禁用
      isParentAgent: false, // 是否为选择上级代理用户
      parentAgentDetail: null, // 上级代理信息
      dateFormat: 'YYYY-MM-DD',
      rangePicker: [],
      form: {
        // agentName: undefined,
        agentLevel: undefined,
        parentAgent: '',
        parentAgentName: '',
        provinceCode: undefined,
        cityCode: undefined,
        countryCode: undefined,
        agentGoodsType: 4,
        securityDeposit: 0,
        membershipNumber: 0,
        ratio: 0,
        validPeriodStart: undefined,
        validPeriodEnd: undefined
      },
      provinceName: "", // 省级名字
      cityName: "", // 市级名字
      countryName: "", // 县级名
      agentLevelArr: [], // 代理等级数组
      // agentIds: [], // 选中代理等级的ID数组
      provinceCodeArr: [], // 省级数组
      cityCodeArr: [], // 市级数组
      countryCodeArr: [], // 县级数组
      rules: {
        agentLevel: [
          { required: true, message: "请选择代理等级", trigger: "change" },
        ],
        provinceCode: [
          { required: true, message: "请选择代理区域", trigger: "change" },
        ],
        // parentAgent: [
        //   { required: true, message: "请选择上级代理", trigger: "change" },
        // ],
        ratio: [
          { required: true, message: "请输入分成比例", trigger: "change" },
        ],
        validPeriodStart: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
      },
      userDetail: {
        headUrl: "",
        realName: "",
        userName: "",
        phone: "",
        key: "", // 用户的id
      },
      isShowUser: false, // 是否显示用户
      isUser: false, // 用户是否显示辅助
      goodsNo: [], // 传回后端的商品ID的数组
      goodsMap: [], // 传回后端有商品ID和成本的数组
      tableList: [], // 商品列表数组
      isGoods: false, // 没有选择商品辅助
      isShowParentAgentError: false, // 没有选择上级代理
    };
  },
  methods: {
    moment,
    // 选择用户按钮
    selectUserClick() {
      this.isParentAgent = false;
      this.modalVisible = true;
    },
    // 商品关闭按钮
    closeHandle() {
      this.userDetail = null;
      this.isShowUser = false;
    },
    // 更换用户
    editUser() {
      this.selectUserClick();
    },
    // 代理等级下拉框改变的回调
    agentLevelChange(value) {
      // this.agentIds = value;
      this.form.agentLevel = value;
    },
    // 选择上级代理用户按钮点击
    selectParentAgent() {
      this.isParentAgent = true;
      this.modalVisible = true;
    },
    deleteParentAgent() {
      this.parentAgentDetail = null;
      this.form.parentAgent = '';
      this.form.parentAgentName = '';
    },
    // 省级改变的回调
    provinceCodeChange(value, option) {
      this.form.cityCode = "";
      this.form.provinceCode = value;
      this.provinceName = option.componentOptions.children[0].text;
      this.clearCityCode();
      this.clearCountryCode();
      this.GetAllCityByCodeFn(value);
    },
    // 市级改变的回调
    cityCodeChange(value, option) {
      this.form.cityCode = value;
      this.cityName = option.componentOptions.children[0].text;
      this.clearCountryCode();
      this.getAllCountryByCityFn(value);
    },
    // 县级改变的回调
    countryCodeChange(value, option) {
      this.form.countryCode  = value;
      this.countryName = option.componentOptions.children[0].text;
    },
    clearCityCode() {
      this.form.cityCode = '';
      this.cityName = '';
    },
    clearCountryCode() {
      this.form.countryCode  = '';
      this.countryName = '';
    },
    // 有效期改变的回调
    onDateRangeChange(date, dateString) {
      this.form.validPeriodStart = dateString[0];
      this.form.validPeriodEnd = dateString[1];
      this.rangePicker = [
        moment(dateString[0], this.dateFormat),
        moment(dateString[1], this.dateFormat)
      ]
    },
    // 代理商品改变的回调
    agentGoodsTypeChange(e) {
      this.form.agentGoodsType = e.target.value;
    },
    // 添加商品的按钮
    addGoods() {
      this.checkboxModalVisible = true;
    },

    // 返回的按钮
    backClick() {
      localStorage.setItem("proxyActiveKey", "1");
      this.$router.go(-1);
    },
    // 用户模态框的取消按钮
    modalCancel() {
      this.modalVisible = false;
    },
    // 用户模态框的ok按钮
    modalOk(obj) {
      if (obj.key) {
        if (obj.isParentAgent) {
          this.form.parentAgent = obj.agentUserId;
          this.form.parentAgentName = obj.realName;
          this.parentAgentDetail = obj;
          this.isShowParentAgentError = false;
        } else {
          this.userDetail = obj;
          this.isShowUser = true;
        }
        this.modalVisible = false;
      } else {
        this.modalVisible = false;
      }
    },
    // 复选框的取消按钮
    checkboxCancel() {
      this.checkboxModalVisible = false;
    },
    // 复选框的确定按钮
    checkboxOk(courseKeys, courseRows, memberKeys, memberRows) {
      if (courseKeys.length > 0 || memberKeys.length > 0) {
        this.goodsNo = courseKeys.concat(memberKeys);
        this.fuCourseSelectedRowKeys = courseKeys;
        this.fuMemberlectedRowKeys = memberKeys;
        this.tableList = courseRows.concat(memberRows);
        this.fuCourseRows = courseRows;
        this.fuMemberRows = memberRows;
      }
      this.checkboxModalVisible = false;
    },
    // 选中数据后table 删除事件
    delHandle(record) {
      let num = record.courseNo ? record.courseNo : record.memberNo;
      this.tableList.forEach((item, index) => {
        if (item.courseNo == num) {
          this.tableList.splice(index, 1);
          this.goodsNo.splice(index, 1);
        } else if (item.memberNo == num) {
          this.tableList.splice(index, 1);
          this.goodsNo.splice(index, 1);
        }
      });
      this.fuCourseSelectedRowKeys.forEach((item, index) => {
        if (item == num) {
          this.fuCourseSelectedRowKeys.splice(index, 1);
          this.fuCourseRows.splice(index, 1);
        }
      });
      this.fuMemberlectedRowKeys.forEach((item, index) => {
        if (item == num) {
          this.fuMemberlectedRowKeys.splice(index, 1);
          this.fuMemberRows.splice(index, 1);
        }
      });
    },
    // 保存的按钮
    keepClick() {
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 3000);
      if (!this.isShowUser) {
        this.isUser = true;
      }
      // 未选择上级代理时显示错误提示
      const needParentAgent = this.form.agentLevel > 1;
      this.isShowParentAgentError = needParentAgent && !this.form.parentAgent;
      if (this.isShowParentAgentError) {
        return;
      }
      // 不需要上级代理时清空数据
      if (!needParentAgent) {
        this.deleteParentAgent();
      }
      let id = this.$route.params.id;
      if (this.form.agentGoodsType == 4 && this.tableList.length == 0) {
        this.isGoods = true;
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const list = this.fuCourseRows.concat(this.fuMemberRows);
            this.goodsMap = list.map(item => {
              return {
                goodsNo: item.courseNo || item.memberNo,
                cost: item.cost
              };
            });
            if (id == "0") {
              GgentSettingApi({
                uid: this.userDetail.key,
                // agentIds: this.form.agentName,
                agentGoodsType: this.form.agentGoodsType,
                provinceCode: this.form.provinceCode,
                provinceName: this.provinceName,
                cityCode: this.form.cityCode,
                cityName: this.cityName,
                countryCode: this.form.countryCode,
                countryName: this.countryName,
                goodsNo: this.goodsNo,
                ...this.form,
                goodsMap: this.goodsMap
              }).then(({ code }) => {
                if (code == 200) {
                  message.success("保存成功", 2);
                  localStorage.setItem("proxyActiveKey", "1");
                  location.href = "/marketingCenter/proxyManage";
                }
              });
            } else {
              AgentUpdateApi({
                agentUserId: id,
                uid: this.userDetail.key,
                // agentIds: this.agentIds,
                agentGoodsType: this.form.agentGoodsType,
                provinceCode: this.form.provinceCode,
                provinceName: this.provinceName,
                cityCode: this.form.cityCode,
                cityName: this.cityName,
                countryCode: this.form.countryCode,
                countryName: this.countryName,
                goodsNo: this.goodsNo,
                ...this.form,
                goodsMap: this.goodsMap
              }).then(({ code }) => {
                if (code == 200) {
                  message.success("保存成功", 2);
                  localStorage.setItem("proxyActiveKey", "1");
                  location.href = "/marketingCenter/proxyManage";
                }
              });
            }
          } else {
            message.error("还有必填项未完成");
          }
        });
      }
    },
    updateForm(data = {}) {
      Object.keys(this.form).forEach(key => {
        if (typeof data[key] !== 'undefined') {
          this.form[key] = data[key];
        }
      });
      // 有效期
      this.rangePicker = [
        moment(this.form.validPeriodStart, this.dateFormat),
        moment(this.form.validPeriodEnd, this.dateFormat)
      ];
    },
    // 代理等级下拉栏函数
    GgentSelectlabelFn() {
      GgentSelectlabelApi().then(({ code, data }) => {
        if (code == 200) this.agentNameArr = data;
      });
    },
    // 查询所有省份函数
    ShowAllProvinceFn() {
      ShowAllProvinceApi().then(({ code, data }) => {
        if (code == 200) this.provinceCodeArr = data;
      });
    },
    // 根据省级code获取所有市级函数
    GetAllCityByCodeFn(provinceCode) {
      GetAllCityByCodeApi({ code: provinceCode }).then(({ code, data }) => {
        if (code == 200) this.cityCodeArr = data;
      });
    },
    getAllCountryByCityFn(cityCode) {
      GetAllCountryByCityApi({code: cityCode}).then(({code, data}) => {
        if (code === 200) this.countryCodeArr = data;
      });
    },
    // 代理用户详情函数
    GgentUserInfoFn() {
      GgentUserInfoApi({ agentUserId: this.$route.params.id }).then(
        ({ code, data }) => {
          if (code == 200) {
            this.userDetail.key = data.userId;
            this.userDetail.headUrl = data.headUrl;
            this.userDetail.realName = data.realName;
            this.userDetail.userName = data.userName;
            this.userDetail.phone = data.phone;
            // this.form.agentName = data.agentId;
            this.updateForm(data);
            // this.agentIds.push(data.agentId);
            this.form.provinceCode = data.province;
            // this.form.agentGoodsType = data.agentGoodsType;
            this.provinceName = data.provinceName;
            // 处理市数据
            this.form.cityCode = data.city;
            this.cityName = data.cityName;
            this.GetAllCityByCodeFn(data.province);
            // 处理县数据
            this.form.countryCode = data.country;
            this.countryName = data.countryName;
            if (data.country) {
              this.getAllCountryByCityFn(data.city);
            }
            if (data.goods && data.goods.length > 0) {
              data.goods.map((item) => {
                item.courseType = item.type;
                if (item.type == 1) {
                  item.name = item.goodsName;
                  item.courseNo = item.goodsNo;
                  this.fuCourseSelectedRowKeys.push(item.goodsNo);
                  this.fuCourseRows.push(item);
                } else if (item.type == 2) {
                  item.memberName = item.goodsName;
                  item.memberNo = item.goodsNo;
                  this.fuMemberlectedRowKeys.push(item.goodsNo);
                  this.fuMemberRows.push(item);
                }
              });
              this.tableList = data.goods;
              this.goodsNo = data.goodsNo;
            }
          }
        }
      );
    },
  },
};
</script>
 
<style lang="less" scoped>
.product-box {
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  .product-close {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
  .product-wrap {
    height: 86px;
    .left-wrap {
      .product-img {
        margin-right: 10px;
        height: 86px;
        .p-img {
          height: 86px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      .product-info {
        flex-direction: column;
        height: 86px;
        .p-title {
          margin-top: 6px;
          font-size: 14px;
          color: #000;
        }
        .p-price {
          color: #000;
          line-height: 60px;
        }
        .p-type {
          color: #9fa5af;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .right-wrap {
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}
/deep/.product .ant-form-item-control {
  line-height: normal;
}
/deep/.edu-type .ant-form-item-children {
  display: flex;
}
/deep/.edu-type .ant-select:nth-child(2) {
  margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
  width: 100%;
}
/deep/.date-picker .ant-form-item-children {
  display: flex;
  align-items: center;
}
/deep/.ant-form-item-control {
  line-height: 22px;
}
.p-container {
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 20px;
  padding: 10px 0;
  // background-color: #F2F2F2;
}
.info-wrap {
  width: 100%;
  padding: 0 20px;
  .item-info {
    margin-right: 14px;
    .p-img {
      width: 100px;
      height: 85px;
      object-fit: contain;
      border-radius: 2px;
    }
    .p-info {
      margin-left: 10px;
      .p-wrap {
        height: 67px;
      }
      .p-info-name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #353535;
      }
      .p-info-price {
        font-size: 14px;
        color: #fb6161;
        margin-top: 4px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      .p-info-type {
        font-size: 12px;
        color: #888;
        margin: 0 14px 0 0;
      }
    }
  }
}
// 改变滚动条样式
.p-container::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0);
}
.p-container::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.p-container::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>