<template>
  <!-- 单选框弹框组件 -->
  <div>
    <a-modal
      title="添加用戶"
      width="50%"
      :visible="modalVisible"
      okText="确定"
      cancelText="取消"
      @ok="ok"
      @cancel="
        () => {
          $emit('cancel');
        }
      "
    >
      <div class="top">
        <a-input
          v-model="searchVal"
          placeholder="请输入用户账号"
          @pressEnter="searchClick"
          style="width: 200px; margin-right: 20px"
        ></a-input>
        <a-button
          icon="search"
          type="primary"
          :disabled="isDisabledSearch"
          @click="searchClick"
          >搜索</a-button
        >
      </div>
      <!-- 表格 -->
      <a-table
        class="courseTable"
        :columns="columns"
        :scroll="{ y: 300 }"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onChange,
          type: 'radio',
        }"
      >
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { SysUserTabApi, GgentUsersApi } from "@/request/api/marketingCenter";
export default {
  props: ["modalVisible", "isParentAgent"],
  created() {
    this.getList();
    if (this.modalVisible) {
      this.$nextTick(() => {
        let selectWrap = document.querySelector(".courseTable .ant-table-body");
        selectWrap.addEventListener("scroll", () => {
          const scrollDistance =
            selectWrap.scrollHeight -
            selectWrap.scrollTop -
            selectWrap.clientHeight;
          if (scrollDistance <= 200 && this.count > this.tableData.length) {
            ++this.pageNo;
            let countAll = Math.ceil(this.count / this.pageSize);
            if (countAll < this.pageNo) return;
            this.getList();
          }
        });
      });
    }
  },
  data() {
    return {
      isDisabledSearch: false, //  搜索的按钮是否禁用
      tableLoading: true, // 表格的loading图
      searchVal: "", // 输入框的值
      pageNo: 1,
      pageSize: 20,
      columns: [
        {
          title: "账号",
          dataIndex: "userName",
          key: "userName",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "姓名",
          dataIndex: "realName",
          key: "realName",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: "手机号码",
          dataIndex: "phone",
          key: "phone",
          scopedSlots: { customRender: "phone" },
        },
      ],
      tableData: [{ key: "0" }],
      selectedRows: {}, // 单选框选中的对象
      selectedRowKeys: [], // 选中的key(id)数组
    };
  },
  methods: {
    // 搜索的按钮
    searchClick() {
      this.tableData = [];
      this.getList();
    },
    // 用户列表选择事件
    onChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = {...selectedRows[0], isParentAgent: this.isParentAgent};
    },
    // 确定的按钮
    ok() {
      this.$emit("ok", this.selectedRows);
    },
    // 获取用户列表函数
    SysUserTabFn() {
      SysUserTabApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        keyword: this.searchVal,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          let newData = data.data.map((item) => {
            return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
          });
          this.tableData = [];
          this.tableData = this.tableData.concat(newData);
          this.count = data.count;
        }
      });
    },
    // 获取代理列表
    GgentUsersFn() {
      console.log('## GgentUsersFn')
      GgentUsersApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        keyword: this.searchVal,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          let newData = data.data.map((item) => {
            return {
              key: item.uid,
              ...item
            };
          });
          this.tableData = [];
          this.tableData = this.tableData.concat(newData);
          this.count = data.count;
        }
      });
    },
    getList() {
      if (this.isParentAgent) {
        this.GgentUsersFn();
      } else {
        this.SysUserTabFn();
      }
    }
  },
};
</script>
 
<style lang="less" scoped>
.top {
  margin-bottom: 20px;
}
// 改变滚动条样式
/deep/ .ant-table-body::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0);
}
/deep/ .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
/deep/ .ant-table-body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>